import { Text } from '@mantine/core';

function Biofunctions() {
  return (
    <Text p="xl" ta="center">
      PLACE HOLDER
    </Text>
  );
}

export default Biofunctions;
